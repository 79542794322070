import React from "react"
import { PlayFill } from "react-bootstrap-icons"
import { StaticImage } from "gatsby-plugin-image"

import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionTitle from "../../components/SectionTitle"

const Video2 = ({ className }) => {
  return (
    <>
      <Section className={`relative bg-neutral-100 !pb-0 ${className}`}>
        <Container>
          {/* wrap */}
          <div className="max-w-[650px] mx-auto">
            <div data-aos="fade-in">
              <SectionTitle className="text-center relative z-40 !pb-0">
                Our <span className="text text-primary-600">approach </span>
                to helping world-leading brands
              </SectionTitle>
            </div>

            <div className="relative z-30 translate-y-[20%]">
              <div data-aos="zoom-in">
                <StaticImage
                  src="../../images/video-image-2.png"
                  alt=""
                  className="w-full shadow-xl rounded-3xl"
                />
              </div>
              <div className="animate-pulse">
                <a
                  href="https://www.youtube.com/watch?v=IUN664s7N-c"
                  className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 Shape h-11 tablet:h-[72px] w-11 tablet:w-[72px] rounded-full bg-white text-black hover:text-white hover:bg-primary-600 duration-300 shadow-md flex justify-center items-center"
                  target="_blank"
                  rel="noreferrer"
                >
                  <PlayFill className="text-display-xs tablet:text-display-sm" />
                </a>
              </div>
            </div>
          </div>
          <div className="absolute inset-0 overflow-hidden">
            <div className="absolute top-0 left-0 z-10 -translate-x-1/2 translate-y-1/2">
              <div
                data-aos="fade-right"
                className=" h-[120px] w-[120px] laptop:h-[220px] laptop:w-[220px] border-[24px] laptop:border-[42px] border-neutral-200"
              ></div>
            </div>
            <div className="absolute bottom-0 right-0 translate-x-1/2 translate-y-1/2 rounded-full">
              <div
                data-aos="fade-up-left"
                className=" h-[120px] w-[120px] laptop:h-[220px] laptop:w-[220px] z-20 border-[24px] rounded-full laptop:border-[42px] border-primary-100"
              ></div>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default Video2
