import React from "react"

import SectionText from "../SectionText"
import TextSmall from "../TextSmall"

const FeaturCard3 = ({
  title = "Smart Suggestions ",
  text = "Dynamically target high-payoff intellectual capital for customized technologies. Objectively integrate emergin.",
  icon,
  iconClassName = "",
  className = "",
}) => {
  return (
    <>
      <div
        data-aos="fade-up"
        className={`flex max-w-[340px] mx-auto laptop:gap-8 gap-6 bg-white  rounded-2xl ${className} `}
      >
        <div
          className={`h-[52px] flex-shrink-0 w-[52px] flex justify-center items-center rounded-lg bg-white shadow-p-lg text-primary-600 ${iconClassName}`}
        >
          {icon}
        </div>
        {/* card-texts */}
        <div className="flex flex-col items-start justify-start gap-3">
          <SectionText
            isHero="false"
            className="!font-medium !text-neutral-700 !pb-0"
          >
            {title}
          </SectionText>
          <TextSmall>{text}</TextSmall>
        </div>
      </div>
    </>
  )
}
export default FeaturCard3
