import React from "react"
import { Laptop, Lightbulb, Magic, PieChart } from "react-bootstrap-icons"

import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionTitle from "../../components/SectionTitle"
import SectionText from "../../components/SectionText"
import FeatureCard3 from "../../components/cards/FeatureCard3"

const Feature4 = ({ className }) => {
  return (
    <>
      <Section
        className={`overflow-hidden pt-28 tablet:pt-36 laptop:!pt-[180px] ${className}`}
      >
        <Container>
          {/* section-wrap */}
          <div className="grid items-center justify-center grid-cols-1 laptop:grid-cols-12 ">
            {/* top-part */}
            <div className="col-span-4 pb-10 tablet:pb-16 laptop:pb-0">
              <div className="flex flex-col pb-0 pr-10">
                <div data-aos="fade-up">
                  <SectionTitle className="">
                    Why you should{" "}
                    <span className="text text-primary-600">choose </span>
                    kloft
                  </SectionTitle>
                  <SectionText className="!pb-0">
                    Efficiently unleash cross-media information without
                    cross-media value. Quickly maximize timely deliverables for
                    real-time schemas.
                  </SectionText>
                </div>
              </div>
            </div>

            {/* bottom-part */}
            <div className="col-span-8">
              {/* cards */}
              <div className="grid grid-cols-1 gap-10 tablet:grid-cols-2 laptop:gap-14 ">
                {/* card */}

                <FeatureCard3
                  title="Smart Suggestions"
                  text="Dynamically target high-payoff intellectual capital for customized technologies. Objectively integrate emergin."
                  icon={<Lightbulb />}
                ></FeatureCard3>
                <FeatureCard3
                  title="Native Platform"
                  text="Quickly deploy strategic networks with compelling e-business. Credibly pontificate highly."
                  icon={<Laptop />}
                ></FeatureCard3>
                <FeatureCard3
                  title="Realtime insights"
                  text="Capitalize on low hanging fruit to identify a ballpark value added activity to beta test. Override the digital divide."
                  icon={<PieChart />}
                ></FeatureCard3>

                <FeatureCard3
                  title="Intelligent features"
                  text="Completely synergize scalable e-commerce rather than high standards in e-services. Assertively iterate resource ."
                  icon={<Magic />}
                ></FeatureCard3>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default Feature4
