import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionTitle from "../../components/SectionTitle"
import BlogCard1 from "../../components/blogs/BlogCard1"
import BlogCard2 from "../../components/blogs/BlogCard2"

const Blog2 = ({ className }) => {
  const { image4, image5 } = useStaticQuery(graphql`
    query {
      image4: allFile(filter: { relativePath: { eq: "blog-4.png" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      image5: allFile(filter: { relativePath: { eq: "blog-5.png" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  `)
  return (
    <>
      <Section className={`bg-neutral-50 ${className}`}>
        <Container>
          {/* section-wrap */}
          <div className="flex flex-col gap-8 tablet:gap-14 laptop:gap-[82px]">
            {/* top-part */}
            <div className="flex flex-col items-center justify-center max-w-[564px] mx-auto">
              <div data-aos="fade-in">
                <SectionTitle className="!text-center">
                  Resources to speed up your{" "}
                  <span className="text text-primary-600">product growth</span>
                </SectionTitle>
              </div>
            </div>

            {/* bottom-part */}
            <div className="">
              {/* cards */}
              <div className="grid grid-cols-1 gap-8 tablet:gap-16 tablet:grid-cols-2 laptop:grid-cols-3">
                {/* card */}
                <BlogCard1
                  title="Why do your customers become unhappy?"
                  text="Keeping your eye on the ball while performing a deep dive on the start-up mentality to derive."
                  imageData={image4.nodes[0]}
                  tag="Technology"
                  date="Feb 27, 2021"
                ></BlogCard1>
                <BlogCard1
                  title="The top tools for great prototyping"
                  text="Completely synergize resource taxing relationships via premier niche markets. Professionally cultivate."
                  imageData={image5.nodes[0]}
                  tag="Marketing"
                  date="Jan 15, 2021"
                ></BlogCard1>

                {/* blog-right-part */}
                <div className="flex flex-col gap-10">
                  {/* card */}
                  <BlogCard2
                    text="Uniquely enable accurate supply chains rather than
                        frictionless technology."
                    title=" Why do your customers become unhappy?"
                  ></BlogCard2>
                  <BlogCard2
                    text="Rapaciously utilize enterprise experiences via 24/7
                        markets."
                    title="Website that actually converts visitors."
                  ></BlogCard2>
                  <BlogCard2
                    text="Our commenting feature is a firm favorite among our
                        customers."
                    title="Discover how our business costumers."
                  ></BlogCard2>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default Blog2
