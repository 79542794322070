import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionTitle from "../../components/SectionTitle"
import SectionText from "../../components/SectionText"
import ButtonArrow from "../../components/ButtonArrow"

const About2 = ({ className, link = "#", children }) => {
  return (
    <>
      <Link to={link} className={`asdasd sadsad ${className}`}>
        {children}
      </Link>
      <Section className={`overflow-hidden  ${className} `} to="/about-1">
        <Container>
          {/* section-wrap */}
          <div className="grid items-center justify-center grid-cols-1 laptop:grid-cols-12">
            {/* left-part */}
            <div className="laptop:col-span-5">
              <div className="relative inline-block">
                <StaticImage
                  src="../../images/about-2.png"
                  alt=""
                  className="relative z-20 object-cover h-full rounded-3xl"
                />
                <div className="absolute bottom-0 right-0 z-20 translate-y-1/2 translate-x-1/3">
                  <div
                    data-aos="fade-up-right"
                    data-aos-delay="400"
                    className=" bg-primary-50 h-20 w-20 laptop:w-[100px] laptop:h-[100px] "
                  >
                    <div className="absolute w-12 h-12 bg-secondary-100 top-full right-full"></div>
                  </div>
                </div>
                <div className="absolute top-0 left-0 z-10 inline-block -translate-x-1/2 -translate-y-1/2">
                  <div
                    data-aos="fade-up-left"
                    className="h-[82px] w-[82px] bg-primary-200 "
                  ></div>
                </div>
              </div>
            </div>

            {/* right-part */}
            <div
              data-aos="fade-up"
              className="row-start-1 laptop:row-auto  laptop:col-span-7 laptop:pb-0 pb-24 pl-0 laptop:pl-[120px]"
            >
              <div>
                <SectionTitle className="">
                  A fast way to launch your{" "}
                  <span className="text text-primary-600">product</span>
                </SectionTitle>
                <SectionText className="max-w-[494px]">
                  Uniquely matrix economically sound value through cooperative
                  technology. Competently parallel task fully researched data
                  and enterprise process improvements.
                </SectionText>
              </div>
              <Link className="inline-block !pr-2" to="/about-2">
                <ButtonArrow>Explore more</ButtonArrow>
              </Link>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default About2
