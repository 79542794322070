import React from "react"
import { Link } from "gatsby"

import TextSmall from "../TextSmall"
import Button from "../Button"

const PricingCard2 = ({
  title = "Free",
  price = "$0",
  text = "Try Kloft free for your business with basic features. Upgrade anytime.",
  className = "",
  buttonClassName = "",
  children,
  priceClassName = "",
  titleClassName = "",
  textClassName = "",
  pricingAfterTextClassName = "",
}) => {
  return (
    <>
      <div
        data-aos="fade-up"
        className={`grid grid-cols-1 laptop:grid-cols-12 bg-secondary-50 rounded-2xl ${className}`}
      >
        {/* left-part */}
        <div className="relative flex flex-col items-center justify-center col-span-4 gap-6 px-5 pt-10 laptop:pt-0 ">
          <h6
            className={`font-bold text-neutral-700 text-display-xs tablet:text-display-sm ${titleClassName}`}
          >
            {title}
          </h6>
          <TextSmall className={`!text-center max-w-[348px] ${textClassName}`}>
            {text}
          </TextSmall>
          <div className="absolute hidden laptop:block right-0 h-[90%] w-[1px] bg-neutral-200"></div>
        </div>
        {/* right-part */}
        <div className="flex flex-col items-center justify-center col-span-8 px-6 py-10 laptop:flex-row laptop:px-14">
          <div className="flex flex-col gap-4 laptop:pr-28">{children}</div>
          <div className="flex flex-col items-center justify-start gap-6 mt-10 laptop:mt-0 laptop:items-start">
            <h6 className="font-bold text-neutral-700 text-display-xs tablet:text-display-sm">
              {" "}
              <span
                className={`text-center text text-primary-600 ${priceClassName}`}
              >
                {price}
              </span>{" "}
              <span className={`${pricingAfterTextClassName}`}>per month</span>
            </h6>
            <Link to="#">
              <Button className={`${buttonClassName}`} size={"lg"}>
                Start Free Trial
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
export default PricingCard2
