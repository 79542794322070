import React from "react"
import { Back } from "react-bootstrap-icons"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import PieChartHero3 from "../../images/svg/Pie-chart-hero-3.svg"
import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionTitle from "../../components/SectionTitle"
import SectionText from "../../components/SectionText"
import Button from "../../components/Button"
import ButtonOutline from "../../components/ButtonOutline"

import TextSmall from "../../components/TextSmall"

const Hero3 = () => {
  return (
    <>
      <Section isHero className="relative overflow-hidden">
        <div className="absolute h-[120%] left-[37%] -z-10 bottom-0 right-0 bg-secondary-50"></div>
        <Container>
          {/* section-wrap */}
          <div className="grid items-center justify-center grid-cols-1 laptop:grid-cols-12">
            {/* left-part */}
            <div className="col-span-7 pb-12 tablet:pb-20 laptop:pb-0 laptop:pr-24">
              <SectionTitle isHero={true}>
                Power your workflow with{" "}
                <span className="text text-primary-600">Kloft</span>
              </SectionTitle>

              <SectionText isHero={true}>
                Bring to the table win-win survival strategies to ensure
                proactive domination. At the end of the day, going forward, a
                new normal that has to wards a streamlined cloud solution.
              </SectionText>

              <div className="flex gap-3 tablet:gap-5 button-wrap">
                <Link to="#">
                  <Button className="" size={"2xl"}>
                    Get started
                  </Button>
                </Link>
                <Link to="#Pricing2">
                  <ButtonOutline size={"2xl"}>See Pricing </ButtonOutline>
                </Link>
              </div>
            </div>
            {/* right-part */}
            <div className="col-span-5">
              <div className="relative inline-block">
                <StaticImage
                  src="../../images/hero-3.png"
                  alt=""
                  className=" rounded-3xl"
                />
                <div className="absolute flex items-center justify-center gap-3 px-5 py-3 translate-x-1/2 bg-white rounded-lg shadow-md top-3 right-20 tablet:top-10 ">
                  <Back className="text-primary-600 text-display-xs" />
                  <TextSmall className="!font-normal text-neutral-700">
                    Copied to clipboard
                  </TextSmall>
                </div>

                <div className="absolute bottom-0 z-20 flex items-center justify-center px-5 py-3 bg-white rounded-lg shadow-md -translate-y-[15%] gap-3 tablet:-translate-y-3/4 laptop:-translate-y-2/4 -left-2 tablet:-left-10 ">
                  <div className="relative">
                    <PieChartHero3></PieChartHero3>
                    <p className="absolute font-medium -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2 text-body-xs text-neutral-800">
                      21%
                    </p>
                  </div>
                  <div className="flex flex-col gap-0">
                    <TextSmall className="!font-normal text-neutral-700 mb-1">
                      Uploading document
                    </TextSmall>
                    <p className="font-normal text-neutral-500 card-text text-body-xs">
                      Transfering in progress...
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default Hero3
