import React from "react"
import { ArrowRight } from "react-bootstrap-icons"
import { Link } from "gatsby"

import ButtonArrow from "../ButtonArrow"
import SectionText from "../SectionText"
import TextSmall from "../TextSmall"

const FeaturCard2 = ({
  title = "UI/UX Design ",
  text = "Value through cooperative technology. Competently parallel task fully researched data and enterprise process.",
  icon,
  iconClassName = "",
  className = "",
}) => {
  return (
    <>
      <div
        data-aos="fade-up"
        className={`flex flex-col px-8 pt-10 bg-white pb-7 gap-7 tablet:max-w-none rounded-2xl max-w-[372px] mx-auto ${className}`}
      >
        <div
          className={`h-[52px] w-[52px] flex justify-center items-center rounded-lg bg-primary-50 text-primary-600 ${iconClassName} `}
        >
          {icon}
        </div>
        {/* card-texts */}
        <div className="flex flex-col items-start justify-start gap-3">
          <SectionText
            isHero="false"
            className="!font-medium !text-neutral-700 !pb-0"
          >
            {title}
          </SectionText>
          <TextSmall>{text}</TextSmall>
          <Link className="inline-block !pr-2" to="#">
            <ButtonArrow icon={<ArrowRight />}>Read more</ButtonArrow>
          </Link>
        </div>
      </div>
    </>
  )
}
export default FeaturCard2
