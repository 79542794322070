import React from "react"
import { Bell, PencilSquare, UiChecksGrid } from "react-bootstrap-icons"

import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionTitle from "../../components/SectionTitle"
import SectionText from "../../components/SectionText"
import FeatureCard2 from "../../components/cards/FeatureCard2"

const Feature3 = () => {
  return (
    <>
      <Section className="relative overflow-hidden">
        <Container>
          {/* section-wrap */}
          <div className="flex flex-col justify-center items-center gap-8 tablet:gap-14 laptop:gap-[82px]">
            {/* top-part */}
            <div className="">
              <div
                data-aos="fade-in"
                className="flex flex-col items-center max-w-[552px] justify-center"
              >
                <SectionTitle className="text-center">
                  Choose{" "}
                  <span className="text text-primary-600">according </span>
                  to your plan
                </SectionTitle>
                <SectionText className="!pb-0 text-center">
                  Quickly aggregate B2B users and worldwide potentialities.
                  resource-leveling e-commerce through resource-leveling core
                  competencies.
                </SectionText>
              </div>
            </div>
            <div className="absolute left-0 -z-10 -translate-y-1/4 -translate-x-1/3 top-1/4 w-[180px] h-[180px] rounded-full bg-neutral-50"></div>
            {/* bottom-part */}
            <div className="">
              {/* cards */}
              <div className="grid grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-3 gap-0 tablet:gap-[52px]  ">
                {/* card */}

                <FeatureCard2
                  title="UI/UX Design "
                  text="Value through cooperative technology. Competently parallel task fully researched data and enterprise process."
                  icon={<PencilSquare />}
                ></FeatureCard2>

                <FeatureCard2
                  className="!shadow-none tablet:!shadow-lg tablet:!bg-white"
                  title="Developing"
                  text="Uniquely enable accurate supply chains rather than frictionless technology. Globally network focused materials."
                  icon={<UiChecksGrid />}
                  iconClassName="bg-secondary-50 text-secondary-600"
                ></FeatureCard2>

                <FeatureCard2
                  title="Easy for everyone"
                  text="Markets via plug-and-play networks. Dynamically procrastinate B2C users after installed base benefits."
                  icon={<Bell />}
                  iconClassName="!bg-success-50 !text-success-600"
                ></FeatureCard2>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default Feature3
