import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionTitle from "../../components/SectionTitle"
import SectionText from "../../components/SectionText"
import ListItem from "../../components/ListItem"
const About3 = () => {
  return (
    <>
      <Section className="overflow-hidden bg-neutral-50 ">
        <Container>
          {/* section-wrap */}
          <div className="grid grid-cols-1 laptop:grid-cols-12">
            {/* left-part */}
            <div className="col-span-7 pr-0 pb-12 tablet:pb-20 laptop:pb-0 laptop:pr-[124px]">
              <div data-aos="fade-up">
                <SectionTitle className="">
                  Keep you business truely{" "}
                  <span className="text text-primary-600">competitive</span>
                </SectionTitle>
                <SectionText className="">
                  Quickly communicate enabled technology and turnkey leadership
                  skills. Uniquely enable accurate supply chains rather than
                  frictionless technology. Globally network focused materials.
                </SectionText>
              </div>
              <div
                data-aos="fade-up"
                className="flex flex-col justify-start gap-4"
              >
                <ListItem>Dashboard Access</ListItem>
                <ListItem>Unlimited Viewers</ListItem>
                <ListItem>Integrations with APIs</ListItem>
                <ListItem>Whitelabelling</ListItem>
              </div>
            </div>

            {/* right-part */}
            <div className="col-span-5">
              <div className="relative inline-block">
                <StaticImage
                  src="../../images/about-3.png"
                  alt=""
                  className="relative z-20 rounded-3xl"
                />
                <div className="absolute top-0 right-0 z-10 translate-x-1/2 -translate-y-1/2">
                  <div
                    data-aos="fade-up-right"
                    className="h-[130px] w-[130px] bg-success-100 "
                  ></div>
                </div>
                <div className="absolute left-0 bottom-0 z-30 translate-y-[15%] -translate-x-1/3">
                  <div data-aos="zoom-in" data-aos-delay="400">
                    <div className="grid scale-75 tablet:scale-100 max-w-[188px] grid-cols-2 grid-rows-2 px-8 py-6 bg-white shadow-lg gap-5 rounded-2xl">
                      <div className="flex flex-col items-center justify-end gap-1">
                        <StaticImage
                          src="../../images/avater-group-1.png"
                          alt=""
                          className="object-cover w-10 h-10 rounded-full"
                        />
                        <p className="font-medium text-body-sm text-neutral-800">
                          Rain
                        </p>
                      </div>
                      <div className="flex flex-col items-center justify-end gap-1">
                        <StaticImage
                          src="../../images/avater-group-2.png"
                          alt=""
                          className="object-cover w-10 h-10 rounded-full"
                        />
                        <p className="font-medium text-body-sm text-neutral-800">
                          Indha
                        </p>
                      </div>
                      <div className="flex flex-col items-center justify-end gap-1">
                        <StaticImage
                          src="../../images/avater-group-3.png"
                          alt=""
                          className="object-cover w-10 h-10 rounded-full"
                        />
                        <p className="font-medium text-body-sm text-neutral-800">
                          Dewi
                        </p>
                      </div>
                      <div className="flex flex-col items-center justify-end gap-1">
                        <StaticImage
                          src="../../images/avater-group-4.png"
                          alt=""
                          className="object-cover w-10 h-10 rounded-full"
                        />
                        <p className="font-medium text-body-sm text-neutral-800">
                          Fewat
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* avater-group */}
          </div>
        </Container>
      </Section>
    </>
  )
}

export default About3
