import React from "react"
import { Link } from "gatsby"

import SectionText from "../SectionText"
import ButtonArrow from "../ButtonArrow"
import TextSmall from "../TextSmall"

const BlogCard2 = ({
  text = "Uniquely enable accurate supply chains rather than frictionless technology.",
  title = "Why do your customers become unhappy?",
}) => {
  return (
    <>
      {" "}
      <div data-aos="fade-up" className="flex flex-col items-start gap-4">
        <div className="flex flex-col gap-3 ">
          <Link className="inline-block !pr-2" to="/blog-details">
            <SectionText
              isHero="false"
              className="!font-bold !text-neutral-700 hover:!text-primary-600 !pb-0  duration-300 ease-out"
            >
              {title}
            </SectionText>
          </Link>
          <TextSmall className="!pb-0">{text}</TextSmall>
        </div>
        <Link className="!inline-block !pr-2" to="/blog-details">
          <ButtonArrow text="Read article"></ButtonArrow>
        </Link>
      </div>
    </>
  )
}
export default BlogCard2
