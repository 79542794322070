import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import SectionText from "../SectionText"
import ButtonArrow from "../ButtonArrow"

const BlogCard1 = ({
  title = "Why do your customers become unhappy?",
  date = "Feb 27, 2021",
  tag = "Technology",
  text = "Keeping your eye on the ball while performing a deep dive on the start-up mentality to derive.",
  isHorizontal = false,
  className = "",
  imageData,
}) => {
  const image = getImage(imageData)

  return (
    <>
      <div
        className={`flex flex-col gap-8 ${
          isHorizontal
            ? "laptop:!flex-row laptop:justify-center laptop:items-center"
            : ""
        } ${className}`}
      >
        <div
          className={`relative overflow-hidden rounded-2xl ${
            isHorizontal ? " laptop:w-[55%]" : ""
          } `}
        >
          <Link to="/blog-details" className="group">
            <GatsbyImage
              image={image}
              alt={title}
              className="w-full duration-500 ease-out group-hover:rotate-3 group-hover:scale-105 "
            />
            <div className="absolute bottom-0 left-0 w-full h-1/2 bg-gradient-to-t from-neutral-900 ... "></div>
          </Link>
          <div className="absolute flex items-center gap-3 bottom-5 left-5">
            <Link to="/blog-details">
              <p className="px-3 py-1 font-medium text-body-sm rounded-[20px] text-white bg-primary-500">
                {tag}
              </p>
            </Link>

            <p className="font-medium text-white text-body-sm">{date}</p>
          </div>
        </div>
        {/* card-bottom */}
        <div className={isHorizontal ? "laptop:flex-1" : ""}>
          <Link to="/blog-details">
            <h5 className="pb-3 font-bold duration-300 ease-out tablet:pb-5 text-body-xl tablet:text-display-xs hover:text-primary-600 text-neutral-700">
              {title}
            </h5>
          </Link>
          <SectionText className="tablet:pb-6 !pb-4">{text}</SectionText>
          <Link to="/blog-details" className="inline-block pr-2">
            <ButtonArrow text="Read article"></ButtonArrow>
          </Link>
        </div>
      </div>
    </>
  )
}
export default BlogCard1
