import React from "react"

import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionTitle from "../../components/SectionTitle"
import PricingCard2 from "../../components/cards/PricingCard2"
import ListItem from "../../components/ListItem"

const Pricing2 = () => {
  return (
    <>
      <Section>
        <Container>
          {/* section-wrap */}
          <div className="flex flex-col gap-8 tablet:gap-14 laptop:gap-[82px] justify-center items-center">
            <div data-aos="fade-in">
              <SectionTitle className="max-w-[496px] text-center mx-auto">
                {" "}
                Cost effective{" "}
                <span className="text-center text text-primary-600">
                  pricing
                </span>{" "}
                that scales
              </SectionTitle>
            </div>
            {/* cards */}
            <div className="grid grid-cols-1 gap-6 tablet:gap-11 ">
              <PricingCard2
                title="Free"
                text="Try Kloft free for your business with basic features. Upgrade anytime."
                price="$0"
                priceClassName="text-black"
                buttonClassName=""
                className=""
              >
                <ListItem>Dashboard Access</ListItem>
                <ListItem>Limited 5k Viewers</ListItem>
                <ListItem>Integrations with APIs</ListItem>
                <ListItem iconClassName="!bg-neutral-100" isChecked={false}>
                  5,000+ Members
                </ListItem>
                <ListItem iconClassName="!bg-neutral-100" isChecked={false}>
                  Whitelabelling
                </ListItem>
              </PricingCard2>

              <PricingCard2
                title="Intermediate"
                titleClassName="text-neutral-50"
                text="Free 14-day trial. No credit card required. Cancel anytime."
                textClassName="text-neutral-200"
                price="$50"
                priceClassName="text-secondary-200"
                buttonClassName="bg-neutral-200 text-neutral-800 hover:!bg-neutral-25"
                className="!bg-primary-600"
                pricingAfterTextClassName="text-neutral-25"
              >
                <ListItem className="!text-neutral-100">
                  Dashboard Access
                </ListItem>
                <ListItem className="!text-neutral-100">
                  Limited 5k Viewers
                </ListItem>
                <ListItem className="!text-neutral-100">
                  Integrations with APIs
                </ListItem>
                <ListItem className="!text-neutral-100">5,000 Members</ListItem>
                <ListItem
                  className="!text-neutral-100"
                  iconClassName="!bg-neutral-100"
                  isChecked={false}
                >
                  Whitelabelling
                </ListItem>
              </PricingCard2>
              <PricingCard2
                title="Advanced"
                text="Free 14-day trial. No credit card required. Cancel anytime."
                price="$50"
                buttonClassName=""
                className=""
              >
                <ListItem>Dashboard Access</ListItem>
                <ListItem>Limited 5k Viewers</ListItem>
                <ListItem>Integrations with APIs</ListItem>
                <ListItem>5,000 Members</ListItem>
                <ListItem>Whitelabelling</ListItem>
              </PricingCard2>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default Pricing2
