import React from "react"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import PageWrapper from "../components/PageWrapper"
import Hero3 from "../sections/heroes/Hero3"
import Feature3 from "../sections/features/Feature3"
import Video2 from "../sections/videos/Video2"
import Feature4 from "../sections/features/Feature4"
import About3 from "../sections/abouts/About3"
import About2 from "../sections/abouts/About2"
import Contact1 from "../sections/contacts/Contact1"
import Pricing2 from "../sections/pricings/Pricing2"
import Blog2 from "../sections/blogs/Blog2"

const HomePage3 = ({ data }) => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          menuPosition: "right",
          className: "!bg-transparent",
          onScrollClassName: "!bg-secondary-50/95",
        }}
        footerConfig={{
          hasMiddleSocial: true,
          hasBottomSubscription: true,
          bottomClassName: "bg-primary-25 ",
        }}
      >
        <GatsbySeo
          title={`Kloft - Home 03`}
          description={`We serve globally to modernise static sites to take advantage of the blazing speeds of JAMStack while eliminating monthly maintenance and even probably, server costs.`}
        />
        <Hero3 />
        <Feature3 />
        <Video2 />
        <Feature4 />
        <About3 />
        <About2 />
        <Contact1 />
        <div id="Pricing2">
          <Pricing2 />
        </div>
        <Blog2 />
      </PageWrapper>
    </>
  )
}

export default HomePage3
